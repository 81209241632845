<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_moveLocationAdd" />

      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelectedName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="suppliersSelectedName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single search-autocomplete"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedName }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="suppliersSelectedName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single search-autocomplete"
                    readonly
                    persistent-hint
                  >
                  </v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--カレンダー-->
            <div class="date-style">
              <div v-if="this.beforePage === 'MovLocationInstSearch'">
                <v-text-field
                  outlined
                  dense
                  id="cal"
                  v-model="moveDate"
                  :label="'はい替え日'"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single date-style"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  id="cal"
                  v-model="moveDate"
                  :label="'はい替え日'"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single date-style"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="this.dateBeforeCheckMsg"
                ></v-text-field>
              </div>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <div v-if="this.beforePage === 'MovLocationInstSearch'">
                  <v-date-picker
                    :allowed-dates="allowedDate"
                    v-model="moveDateCal"
                    @input="dateMenu = false"
                  />
                </div>
                <div v-else>
                  <v-date-picker v-model="moveDateCal" @input="dateMenu = false" />
                </div>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="pevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <v-btn-toggle
              mandatory
              v-model="isPaid"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn class="paid-btn" value="01" small>
                {{ $t("btn.btn_paid") }}
              </v-btn>
              <v-btn class="paid-btn" value="02" small>
                {{ $t("btn.btn_free") }}
              </v-btn>
            </v-btn-toggle>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="backPage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!--指示登録ボタン-->
                <v-btn
                  v-if="btnNmToggle == '1'"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="addInst"
                  >{{ $t("btn.btn_instructionAdd") }}</v-btn
                >
                <!-- 指示更新ボタン -->
                <v-btn
                  v-if="btnNmToggle == '2'"
                  :disabled="editableFlg"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="addInst"
                  >{{ $t("btn.btn_instructionUpdate") }}</v-btn
                >
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="postApi"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                    {{ 10 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                    {{ 50 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                    {{ 100 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-form ref="editList" lazy-validation>
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              disable-filtering
              disable-sort
              :hide-default-footer="true"
              height="600px"
              @page-count="pageCount = $event"
            >
              <!-- ヘッダーロット -->
              <template v-slot:[`header.lotNo`]="{ header }">
                <span>
                  <div>{{ header.text }}</div>
                  <span>
                    {{
                      $t("label.lbl_dueDate") +
                      "／" +
                      $t("label.lbl_lotSubkey1") +
                      "／" +
                      $t("label.lbl_lotSubkey2")
                    }}
                  </span>
                </span>
              </template>
              <!-- 移動数-->
              <template v-slot:[`item.moveQty`]="{ item, index }">
                <v-text-field
                  v-if="!item.completeFlg || item.completeFlg == '未処理'"
                  class="text-box input-number"
                  outlined
                  dense
                  v-model="item.moveQty"
                  clear-icon="mdi-close-circle"
                  @change="commaMoveQty(item, index)"
                  @click="clickPop(item.moveQty)"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.inputRequired,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.isUpperNummber,
                  ]"
                  :ref="'moveQty' + index"
                />
                <v-text-field
                  v-else-if="item.completeFlg == '処理済'"
                  class="text-box input-number"
                  outlined
                  dense
                  v-model="item.moveQty"
                  readonly
                />
              </template>
              <!-- 削除ボタン -->
              <template v-slot:[`item.delete`]="{ item }">
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <!--有償・無償-->
              <template v-slot:[`item.isPaid`]="{ item }">
                <v-select :items="paidList" v-model="item.isPaid"></v-select>
              </template>
              <!-- 移動後倉庫/ロケ -->
              <!-- WMS2022DEV-929の対応によりツールチップをコメントアウト -->
              <!-- 再度変更の可能性もあるので残しておきます。 -->
              <template v-slot:[`item.postWareLoc`]="{ item, index }">
                <div class="loke">
                  <div v-if="item.postWareLoc">
                    {{ item.postWareLoc }}
                  </div>
                  <div v-else>
                    <div class="search-autocomplete first-search-item">
                      <v-text-field
                        dense
                        v-model="item.postWareLoc"
                        class="txt-single search-autocomplete"
                        readonly
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <v-btn class="ml-auto" small @click="addLocation(index)" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </template>
              <!-- 商品コード・品名 -->
              <template v-slot:[`item.productCnCd`]="{ item }">
                <div v-if="item.productName.length > 20">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                        {{ item.productCnCd }}<br />
                        {{ item.incidental }}<br />
                        {{ item.productName }}
                      </div>
                    </template>
                    <div>
                      {{ item.productCnCd }}<br />
                      {{ item.incidental }}<br />
                      {{ item.productName }}
                    </div>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>
                    {{ item.productCnCd }}<br />
                    {{ item.incidental }}<br />
                    {{ item.productName }}
                  </div>
                </div>
              </template>
              <!-- ロット/S/N -->
              <template v-slot:[`item.lotNo`]="{ item }">
                <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="width: 300px" v-bind="attrs" v-on="on">
                        {{ item.lotNo }}<br />
                        {{ item.dueDate }}
                      </div>
                    </template>
                    <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                  </v-tooltip>
                </div>
                <div v-else>
                  {{ item.lotNo }}<br />
                  {{ item.dueDate }}
                </div>
              </template>
            </v-data-table>
            <!-- ページング-->
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
            </div>
          </v-container>
        </v-form>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 登録後表示ダイアログ -->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :firstPageFlag="infoDialog.firstPageFlag"
        :changeFlag="infoDialog.changeFlag"
        :forthPageFlag="infoDialog.forthPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- バリデーションチェックエラー -->
      <v-dialog v-model="alertDialogFlg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 空きスペースダイアログ -->
      <v-dialog v-model="dialogLocation" :max-width="1300">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:close="close"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";

import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import LocationDialog from "../../views/common/FreeSpaceLocationList.vue";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  name: appConfig.SCREEN_ID.P_MOV_002,
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
    sideMenu,
    NavBar,
    LocationDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 前画面から受け取る値
    editableFlg: false,
    beforePage: "",
    itemCd: "",
    lotNo: "",
    serial: "",
    dueDate: "",
    lotSubkey1: "",
    lotSubkey2: "",
    warehouse: "",
    location: "",
    status: "",
    preWarehouse: "",
    preLocation: "",
    processDivision: "",
    // 取引先
    suppliersSelected: "",
    // 有償無償
    isPaid: "02",
    // 有償無償登録プルダウン
    paidList: [
      {
        text: i18n.tc("btn.btn_paid"),
        value: "01",
        selected: true,
      },
      {
        text: i18n.tc("btn.btn_free"),
        value: "02",
        selected: false,
      },
    ],
    // バリデーションチェック時出現ダイアログ
    alertDialogFlg: "",
    alertMessage: "",
    // ロケーション情報
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
      clientSid: "",
      itemStandardSid: "",
      itemCd: "",
      itemNm: "",
    },
    // ボタン名表示切り替え**指示登録・指示更新**
    btnNmToggle: "1",
    // 削除したい登録済みのデータ
    // 削除した場合に裏で削除したいデータをもっておく
    deleteList: [],
    paidCheck: [],
    // はい替え日
    moveDate: "",
    moveDateCal: "",
    // はい替え日チェックエラーメッセージ
    dateBeforeCheckMsg: "",
    dateMenu: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 総件数
    totalCount: 0,
    // メニュー
    openMenu: null,
    // ロケーションダイアログ
    dialogLocation: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      changeFlag: false,
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      changeFlag: false,
      firstPageFlag: false,
      forthPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 取引先名
    suppliersSelectedName: "",
    // 一覧
    inputList: [],
    // init時に一時格納するための変数
    fakeInputList: [],
    // ヘッダ
    headerItems: [
      // 明細No
      {
        text: i18n.tc("label.lbl_detailNo"),
        value: "instNo",
        width: "3%",
        sortable: false,
        align: "left",
      },
      // 品番・品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "15%",
        align: "left",
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "17%",
        align: "left",
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 移動前倉庫/ロケ
      {
        text: i18n.tc("label.lbl_preWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "locations",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stockQty",
        width: "7%",
        align: "right",
      },
      // 移動後倉庫/ロケ
      {
        text: i18n.tc("label.lbl_postWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "postWareLoc",
        width: "15%",
        align: "center",
        class: "asta",
      },
      // 移動数
      {
        text: i18n.tc("label.lbl_moveQty"),
        value: "moveQty",
        width: "8%",
        align: "center",
        class: "asta",
      },
      // 有償・無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "7%",
        align: "center",
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "3%",
        align: "center",
      },
    ],
    detailDialogData: {},
    valid: true,
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        } else {
          // 処理なし
        }
        if (0 < Number(commonUtil.zen2han(value).replaceAll(",", ""))) {
          return true;
        } else {
          return i18n.tc("check.chk_inputMore");
        }
      },
      isUpperNummber: (value) => {
        if (value == "" || value == null || value == undefined) {
          return true;
        } else {
          if (event !== undefined && event.target.tagName == "INPUT") {
            // a = 在庫数のtag
            let a =
              event.target.parentElement.parentElement.parentElement.parentElement.parentElement
                .parentElement;
            let aa = a.children[5].innerText;
            let b = Number(commonUtil.zen2han(value).replaceAll(",", ""));
            let qNum = Number(commonUtil.zen2han(aa).replaceAll(",", ""));
            if (b > qNum) {
              // 値が入っていない場合はチェックなし
              return i18n.tc("check.chk_correlation_moveLoc");
            }
          }
          return true;
        }
      },
    },
  }),
  methods: {
    // 登録ボタン押下
    addInst() {
      //エラー内容初期化
      this.dateBeforeCheckMsg = i18n.tc("");

      let paidCheck = [];
      let t = 0;
      // はい替え日の入力チェック（画面ヘッダー部分）
      if (this.$refs.form.validate()) {
        // 削除する場合
        if (this.deleteList.length) {
          // 登録してもいいかの確認ダイアログ
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        }

        // はい替え指示一覧画面からの遷移の場合
        if (this.beforePage === "MovLocationInstList") {
          // はい替え日が現在の日付以前かのチェック
          if (!dateTimeHelper.isDateAfterTheDay(this.moveDate)) {
            this.dateBeforeCheckMsg = i18n.tc("check.chk_instructionDate");
            return;
          }
        }

        // 削除なしの場合明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          // 移動後倉庫が選択されていなければアラートを表示
          if (!this.inputList[i].postWareLoc) {
            // 移動数のバリデーションチェック
            this.alertDialogFlg = true;
            this.alertMessage = i18n.tc("check.chk_warehouse");
            return;
          }

          // 登録時に有償があるかのチェック
          if (this.inputList[i].isPaid == "01") {
            paidCheck.push(this.inputList[i]);
          }

          let a = this.inputList[i].stockQty;
          let b = this.inputList[i].moveQty;
          if (b == undefined) {
            this.alertMessage = i18n.tc("check.chk_inputMoveNumber");
            this.alertDialogFlg = true;
            return;
          } else {
            if (a.length > 3) {
              a = Number(a.replaceAll(",", ""));
            } else {
              a = Number(a);
            }
            if (b.length > 3) {
              b = Number(b.replaceAll(",", ""));
            } else {
              b = Number(b);
            }
            if (b > a) {
              this.alertMessage = i18n.tc("check.chk_correlation_moveLoc");
              // this.infoDialog.title = appConfig.DIALOG.title;
              this.alertDialogFlg = true;
              return;
            } else {
              ++t;
              if (this.$refs.editList.validate() && t == this.inputList.length) {
                //     // 有償を１つでも選択時、有償でいいかの確認ダイアログ
                if (paidCheck.length !== 0) {
                  this.ConfirmDialog.screenFlag = false;
                  this.ConfirmDialog.changeFlag = true;
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
                  this.ConfirmDialog.redMessage = "有償";
                  this.ConfirmDialog.isOpen = true;
                } else {
                  // 登録してもいいかの確認ダイアログ
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
                  this.ConfirmDialog.isOpen = true;
                  this.ConfirmDialog.screenFlag = true;
                  this.ConfirmDialog.changeFlag = false;
                }
              } else {
                for (var j = 0; j < this.inputList.length; j++) {
                  let checkPosition = String(j);
                  if (!this.inputList[j].moveQty) {
                    // 関係ないレコードの入力チェックエラーを解除
                    eval("this.$refs.moveQty" + checkPosition + ".resetValidation()");
                  }
                }
              }
            }
          }
        }
      }
    },
    // 戻るボタン押下
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 元画面が一覧の場合
      if (this.beforePage === "MovLocationInstList") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MOV_003,
          params: {
            suppliersSelected: this.suppliersSelected,
            moveDate: this.$route.params.moveDate,
            itemCd: this.itemCd,
            status: this.status,
            lotNo: this.lotNo,
            serial: this.serial,
            dueDate: this.dueDate,
            lotSubkey1: this.lotSubkey1,
            lotSubkey2: this.lotSubkey2,
            preWarehouse: this.preWarehouse,
            preLocation: this.preLocation,
            warehouse: this.warehouse,
            location: this.location,
            processDivision: this.processDivision,
            prodNameList: this.$route.params.prodNameList,
            prelocationList: this.$route.params.prelocationList,
            locationList: this.$route.params.locationList,
            isPaid: this.$route.params.searchIsPaid,
            name: appConfig.SCREEN_ID.P_MOV_002,
          },
        });
        // 元画面が登録（検索）の場合
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MOV_001,
          params: {
            suppliersSelected: this.suppliersSelected,
            itemCd: this.itemCd,
            itemCdList: this.$route.params.itemCdList,
            lotNo: this.lotNo,
            serial: this.serial,
            dueDate: this.dueDate,
            lotSubkey1: this.lotSubkey1,
            lotSubkey2: this.lotSubkey2,
            warehouse: this.warehouse,
            location: this.location,
            locationList: this.$route.params.locationList,
            status: this.status,
            itemsPerPage: this.$route.params.itemsPerPage,
            itemsPerPageBtn: this.$route.params.itemsPerPageBtn,
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 登録ボタン実行
    postApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MOV_002;

      var locationMove = [];
      // 指示を削除する場合
      if (this.deleteList.length !== 0) {
        locationMove.push({
          officeSid: sessionStorage.getItem("sales_office_sid"), //営業所SID
          clientSid: this.suppliersSelected, //取引先SID,
          locaMoveSid: this.deleteList[0].instNo, //指示SID
          yyyymmddHhmmss: dateTimeHelper.convertUTC(this.moveDate), //はい替え日
          fromWarehouseSid: this.deleteList[0].warehouseSid, //from倉庫SID
          fromLocationSid: this.deleteList[0].locationSid, //fromロケーションSID
          toWarehouseSid: this.deleteList[0].postWarehouseSid, //to倉庫SID
          toLocationSid: this.deleteList[0].postLocationSid, //toロケーションSID
          itemStandardSid: this.deleteList[0].itemSid, //商品SID
          stock: this.deleteList[0].moveQty ? this.deleteList[0].moveQty.replaceAll(",", "") : "", //移動数
          updateDatetime: this.deleteList[0].updateDatetime, //更新日
          deleteFlg: "1", //削除する
        });
        // 指示を登録・修正する場合
      } else {
        if (this.inputList == null || this.inputList.length < 1) {
          this.infoDialog.message = i18n.tc("check.che_nothingOrder");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.loadingCounter = 0;
          return;
        }
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].freeCostDiv = this.inputList[i].isPaid;
          locationMove.push({
            officeSid: sessionStorage.getItem("sales_office_sid"), //営業所SID
            clientSid: this.suppliersSelected, //取引先SID
            yyyymmddHhmmss: dateTimeHelper.convertUTC(this.moveDate), //はい替え日
            fromWarehouseSid: this.inputList[i].warehouseSid, //from倉庫SID
            fromLocationSid: this.inputList[i].locationSid, //fromロケーションSID
            toWarehouseSid: this.inputList[i].postWarehouseSid, //to倉庫SID
            toLocationSid: this.inputList[i].postLocationSid, //toロケーションSID
            itemStandardSid: this.inputList[i].itemSid, //商品SID
            itemCd: this.inputList[i].productCnCdShow, //商品cd
            freeCostDiv: this.inputList[i].freeCostDiv, //有償無償
            lotNo: this.inputList[i].lotNoShow,
            stock: this.inputList[i].moveQty ? this.inputList[i].moveQty.replaceAll(",", "") : "", //移動数
            deleteFlg: "0", //削除しない
            qualityDiv: this.inputList[i].statusDiv, //ステータス区分
            locaMoveSid: this.inputList[i].instNo.length == 10 ? this.inputList[i].instNo : "", //指示SID
            updateDatetime: this.inputList[i].updateDatetime
              ? this.inputList[i].updateDatetime
              : "", //更新日
          });
        }
      }

      body.reqIdv.locationMove = locationMove.slice();
      // console.debug("postApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_LOCATION_MOVE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.forthPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != undefined) {
        if (val != null && val.length > 3) {
          pop = val.replaceAll(",", "");
        }
        myName.value = pop;
      }
    },
    /**
     * ロケーション予約画面処理
     * @param 選択した明細のインデックス
     */
    addLocation(index) {
      this.locInfo = {};
      // 画面項目を子画面に引き渡す。
      // 商品SID
      this.locInfo.itemStandardSid = this.inputList[index].itemSid;
      // はい替え日
      this.locInfo.referenceDate = this.moveDate;
      // 取引先ID
      this.locInfo.clientSid = this.suppliersSelected;
      // ロケーション
      this.locInfo.locationSid = this.inputList[index].locationSid;
      // 画面表示用(倉庫・ロケ)
      this.locInfo.locations = this.inputList[index].locations;
      // 品番
      this.locInfo.itemCd = this.inputList[index].productCnCdShow;
      // 品名
      this.locInfo.itemNm = this.inputList[index].productName;
      // インデックス(選択した明細のインデックス)
      this.locInfo.index = index;
      // 画面表示
      this.dialogLocation = true;
      // 画面ID
      this.locInfo.name = appConfig.SCREEN_ID.P_MOV_002;
    },
    u_10_on() {
      this.itemsPerPage = 10;
      this.page = 1;
    },
    u_10_clear(val) {
      this.itemsPerPage = val;
      this.page = 1;
    },
    /**
     * 子画面ロケーション選択時処理
     * @param {} data 子画面データ
     */
    saveLocation(data) {
      // inputListから対象のデータを取得する。
      let item = this.inputList[this.locInfo.index];
      // 画面表示用設定（倉庫名/ロケーションNo）
      item.postWareLoc = data.warehouse + "/" + data.location;
      // 移動先ロケーションSID
      item.postLocationSid = data.locationSid;
      // 移動先倉庫SID
      item.postWarehouseSid = data.warehouseSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    // 初期化
    init() {
      this.beforePage = this.$route.params.name;
      this.suppliersSelectedName = this.$route.params.clientName;
      this.suppliersSelected = this.$route.params.supplier;
      // 在庫詳細を呼び出して画面描画させるために一旦fakeInputListに値を退避
      this.fakeInputList = this.$route.params.checkList.slice();
      // 指示一覧からの遷移の場合：指示Noが10桁
      if (this.fakeInputList[0].instNo.length == 10) {
        this.isPaid = this.$route.params.isPaid;
        this.btnNmToggle = "2"; //ボタン名を指示更新に変更
        this.moveDate = dateTimeHelper.convertUTC2JST(this.$route.params.moveDate);
        this.moveDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.moveDate.substr(0, 10))
        );
        // 処理済みか未処理かで指示更新のボタンの制御を行う
        this.editableFlg = this.$route.params.checkList[0].completeFlg == "処理済" ? true : false;
        for (let i = 0; i < this.fakeInputList.length; i++) {
          const lot = this.fakeInputList[i].lotShow;
          this.fakeInputList[i].lotNoShow = lot;
          let lotNoObj = commonUtil.convertLotNo(lot);
          this.fakeInputList[i].lot = lotNoObj.lot;
          // 期限日 + 付属キー１ + 付属キー２
          this.fakeInputList[i].dueDate = lotNoObj.dueDate;
        }
        this.getStockNum();
        this.inputList = this.fakeInputList;
        this.inputList[0].statusDiv = this.$route.params.qualityDiv;
        this.inputList[0].lotNo = this.$route.params.checkList[0].lot;
        // 登録（検索）画面からの遷移の場合：指示Noが10桁ない
      } else {
        for (let i = 0; i < this.fakeInputList.length; i++) {
          this.fakeInputList[i].isPaid = "02";
          this.moveDate = this.$route.params.moveDate;
          this.moveDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
          // postWareLocがないので空でセット
          this.fakeInputList[i].postWareLoc = "";
        }
        this.inputList = this.fakeInputList;
      }
      this.itemCd = this.$route.params.itemCd;
      this.lotNo = this.$route.params.lotNo;
      this.serial = this.$route.params.serial;
      this.dueDate = this.$route.params.dueDate;
      this.lotSubkey1 = this.$route.params.lotSubkey1;
      this.lotSubkey2 = this.$route.params.lotSubkey2;
      this.warehouse = this.$route.params.warehouse;
      this.location = this.$route.params.location;
      this.status = this.$route.params.status;
      this.preWarehouse = this.$route.params.preWarehouse;
      this.preLocation = this.$route.params.preLocation;
      this.processDivision = this.$route.params.processDivision;
      // 総件数
      this.totalCount = this.inputList.length;
    },
    // バリデーションチェックエラー時出現ダイアログ
    okClicked() {
      this.alertDialogFlg = false;
    },
    // 今日以前の日付を選択できないようにする
    allowedDate(val) {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today <= new Date(val);
    },
    // カレンダー
    pevDate() {
      let today = dateTimeHelper.convertJST();
      if (
        this.moveDateCal == dateTimeHelper.dateCalc(today) &&
        this.beforePage === "MovLocationInstSearch"
      ) {
        return;
      }
      let date = new Date(this.moveDateCal);
      this.moveDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDate() {
      let date = new Date(this.moveDateCal);
      this.moveDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    // 移動数カンマ処理
    commaMoveQty(val, index) {
      var comma = commonUtil.zen2han(val.moveQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        this.inputList[index].moveQty = commonUtil.formatToCurrency(Number(comma));
      }
      this.$forceUpdate();
    },
    // 在庫詳細API接続し、在庫数を取得
    getStockNum() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.clientSid = this.suppliersSelected;

      config.params.itemStandardSid = this.fakeInputList[0].itemSid;
      config.params.locationSid = this.fakeInputList[0].locationSid;
      config.params.warehouseSid = this.fakeInputList[0].warehouseSid;
      config.params.qualityDiv = this.fakeInputList[0].qualityDiv;
      config.params.lotNo = this.fakeInputList[0].lotShow;
      // より詳細に絞り込みする
      // config.params.qualityDiv = this.fakeInputList[0].status == "良品" ? "01" : "02" ;
      // config.params.lotNo = this.fakeInputList[0].lot;
      // console.debug("getStockNum() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_STOCKDTL, config)
          .then((response) => {
            // console.debug("getStockNum() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (0 < jsonData.resIdv.stocksDetail.length) {
                this.fakeInputList[0].stockQty = commonUtil.formatToCurrency(
                  Number(jsonData.resIdv.stocksDetail[0].stocks)
                );
                this.inputList = this.fakeInputList.slice();
                this.inputList[0].isPaid = this.$route.params.isPaid;
              }
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate() {
      if (this.moveDate.length == 8) {
        var dt = this.moveDate;
        this.moveDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      // 日付が今日より小さい場合、今日の日付に設定
      if (this.moveDate.replaceAll("/", "") < dateTimeHelper.toStringNowYYYYMMDD()) {
        this.moveDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      }
      if (dateTimeHelper.validDate(this.moveDate, "/")) {
        this.moveDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(this.moveDate));
      } else {
        this.moveDateCal = null;
      }
    },
    // 削除ボタン
    deleteItem(item) {
      // 登録済の指示を削除する場合（DBから削除する）
      if (this.inputList[0].instNo.length == 10) {
        this.editedIndex = this.inputList.indexOf(item);
        // 削除Flgを１にしてポストする必要があるためdataとして持っておく
        this.deleteList = this.inputList.slice();
        // 画面上からは明細削除
        this.inputList.splice(this.editedIndex, 1);
        // 登録する明細を削除する場合
      } else {
        this.editedIndex = this.inputList.indexOf(item);
        this.inputList.splice(this.editedIndex, 1);
        for (let i = 0; i < this.inputList.length; i++) {
          // 明細Noの変更
          this.inputList[i].instNo = i + 1;
        }
      }
    },
    /**
     * 子画面閉じるボタン押下
     */
    close() {
      // ダイアログを閉じる
      this.dialogLocation = false;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    // 有償無償のプルダウン切り替え
    isPaid(item) {
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].isPaid = item;
      }
    },
    dialog(val) {
      val || this.close();
    },
    moveDateCal(val) {
      this.moveDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//引当ボタン 左端個別合わせ
.reservation-btn {
  margin-right: 3%;
  width: 7rem;
}

.paging-position {
  margin-left: 4.5%;
}

.paging-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1.7rem;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}

// トータル検索数
.search-result {
  margin: 0 0 0 5%;
}

.required::after {
  content: " *";
  color: red;
  vertical-align: middle;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.text-overflow {
  width: 400px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.dialog {
  background-color: white;
}

.btn-search {
  margin-left: 5%;
}

.loke {
  display: flex;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
