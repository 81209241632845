<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">
            {{ $t("label.lbl_FreeSpaceLocationList") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 検索項目 -->
    <v-container fluid>
      <v-form ref="form" lazy-validation>
        <v-row class="search-row">
          <!-- 検索方法選択ボタン-->
          <div style="float: left; margin-left: 10px; height: 67px">
            <v-btn-toggle
              mandatory
              v-model="searchMethod"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
              @change="changeSearchMethod"
            >
              <v-btn
                class="paid-btn"
                v-for="item in searchMethodList"
                :key="item.value"
                :value="item.value"
                small
              >
                {{ item.text }}
              </v-btn>
            </v-btn-toggle>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete" v-show="searchMethodDispFlag1" id="get-item-cd">
            <!-- 品番品名 -->
            <v-autocomplete
              dense
              id="get-warehouse"
              v-model="ProdNameSelected"
              :items="prodNameList"
              :label="$t('label.lbl_productCnCd')"
              readonly
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </div>

          <div class="search-autocomplete" v-show="searchMethodDispFlag2">
            <!-- 倉庫 -->
            <v-autocomplete
              dense
              id="get-warehouse"
              v-model="warehouseSelected"
              :items="warehouseNameList"
              :label="$t('label.lbl_warehouse')"
            >
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
            </v-autocomplete>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- ロケーション -->
          <div class="" v-show="searchMethodDispFlag2">
            <v-text-field
              dense
              id="get-location"
              outlined
              v-model="txt_location"
              maxlength="30"
              :label="$t('label.lbl_location')"
              clear-icon="mdi-close-circle"
              clearable
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 移動前倉庫ロケ -->
          <div class="search-autocomplete-full">
            <v-text-field
              dense
              id="get-location"
              readonly
              v-model="locInfo.locations"
              maxlength="30"
              :label="`${$t('label.lbl_preWarehouse')}/${$t('label.lbl_location')}`"
            />
          </div>
        </v-row>
      </v-form>

      <!-- 検索ボタン -->
      <v-row>
        <!-- 検索 -->
        <div class="search-row-exeBtn">
          <div class="btn-area" style="float: left">
            <v-btn color="primary" id="btn-search" class="api-btn" @click="search()">
              {{ $t("btn.btn_search") }}
            </v-btn>
          </div>

          <!-- 閉じるボタン -->
          <div class="btn-area" style="float: right">
            <v-btn color="primary" id="btn-search" class="api-btn" @click="close()">
              {{ $t("btn.btn_close") }}</v-btn
            >
          </div>

          <!-- 推奨ロケ検索 -->
          <!-- <v-col xl="2">
          <v-btn color="primary" @click="autoSearch()" small>
            {{ $t("btn.btn_recommendedLocationSearch") }}
          </v-btn>
        </v-col> -->
        </div>
      </v-row>
      <v-row>
        <div class="display-search-result-row">
          <!--検索結果表示-->
          <div class="text-label" style="float: left">
            <span>総件数：{{ totalCount }}件</span>
          </div>
          <!--表示件数切替部分-->
          <div style="float: right">
            <!--表示件数-->
            <div class="text-label" style="clear: both; float: left">
              {{ $t("label.lbl_itemsPerPage") }}
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!--表示件数切替ボタン-->
            <div style="float: left">
              <v-btn-toggle
                v-model="itemsPerPageBtn"
                color="blue darken-2"
                class="toggle black--text"
                right
              >
                <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                  {{ 10 }}
                </v-btn>
                <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                  {{ 50 }}
                </v-btn>
                <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                  {{ 100 }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>

    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        fixed-header
        :headers="headerItems"
        :items="locationList"
        :page.sync="page"
        :server-items-length="itemsPerPage"
        disable-filtering
        disable-sort
        :hide-default-footer="true"
        height="530px"
      >
        <!-- 倉庫名 -->
        <template v-slot:[`item.warehouse`]="{ item }">
          <div v-if="item.warehouse && item.warehouse.length > 20">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.warehouse }}
                </div>
              </template>
              <span class="text-overflow">{{ item.warehouse }}</span>
            </v-tooltip>
          </div>
          <div class="text-overflow" v-else>{{ item.warehouse }}</div>
        </template>

        <!-- ロケーション -->
        <template v-slot:[`item.location`]="{ item }">
          <div v-if="item.location && item.location.length > 10">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.location }}
                </div>
              </template>
              <span>{{ item.location }}</span>
            </v-tooltip>
          </div>
          <div v-else>{{ item.location }}</div>
        </template>

        <!-- 商品コード・品名 -->
        <template v-slot:[`item.productCd`]="{ item }">
          <div v-if="item.product.length > 40">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                  {{ item.productCd }}
                </div>
                <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                  {{ item.incidental }}
                </div>
                <div class="text-overflow" style="max-width: 400px">
                  {{ item.product }}
                </div>
              </template>
              <div>{{ item.productCd }}</div>
              <div>{{ item.incidental }}</div>
              <div>{{ item.product }}</div>
            </v-tooltip>
          </div>
          <div v-else>
            <div style="width: 400px">{{ item.productCd }}</div>
            <div style="width: 400px">{{ item.incidental }}</div>
            <div style="width: 400px">{{ item.product }}</div>
          </div>
        </template>

        <!-- 設定 -->
        <template v-slot:[`item.action`]="{ item }">
          <v-btn small @click="selectLocation(item)" text>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
      <!-- 設定ボタン押下後エラー時出現エラー -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-container>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
const SEARCH_METHOD_ITEM = "01";
const SEARCH_METHOD_WAREHOUSE_LOCATION = "02";
export default {
  // 親画面から渡される製品コード
  props: {
    locInfo: {},
  },
  components: {
    Loading,
    SimpleDialog,
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    firstDispFlg: true,
    u_10: false,
    // ダイアログ
    updateDialogMessage: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 検索方法
    searchMethod: SEARCH_METHOD_ITEM,
    // 検索方法選択
    searchMethodList: [
      { text: "品番/品名", value: SEARCH_METHOD_ITEM },
      { text: "倉庫/ロケ", value: SEARCH_METHOD_WAREHOUSE_LOCATION },
    ],
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // 検索方法切替フラグ（1:品番/品名、2:倉庫、ロケ）
    searchMethodDispFlag1: true,
    searchMethodDispFlag2: false,
    // 検索項目：ロケ
    txt_location: "",
    // 検索項目：倉庫
    warehouseSelected: "",
    // 検索件数
    totalCount: 0,
    // 総配列から抜き出した 倉庫配列
    warehouseNameList: [],
    // 総データの重複削除配列
    filterWarehouseNameList: [],
    // 総配列から抜き出した ロケーションの配列
    locationNameList: [],
    // 倉庫の基準日
    dateW: "",
    // 検索の際の格納ロケの基準日
    dateL: "",
    // 選択された品番/品名
    ProdNameSelected: "",
    // 品番品名リスト
    prodNameList: [],
    // ロケーションリスト
    locationList: [],
    // 一時保存用ロケーションリスト
    tempLocationList: [],
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "8%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "8%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "8%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_FreeJigCount"),
        value: "emptyJigCount",
        width: "7%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_freeSpace"),
        value: "freeSpace",
        width: "7%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_config"),
        value: "action",
        width: "4%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      console.log(this.locInfo);
      this.ProdNameSelected = this.locInfo.itemStandardSid; // 品番品名を設定
      const list = [{ text: "", value: "" }];
      var itemCd = this.locInfo.itemCd.substring(0, 50).trim();
      var incidental = this.locInfo.itemCd.slice(-50).trim();
      list.push({
        text: `${itemCd}` + ` ${incidental}` + `（${this.locInfo.itemNm}）`,
        value: this.ProdNameSelected,
        name: this.locInfo.itemNm,
      });
      this.prodNameList = list;
      this.locationList = [];
      // 取引先SIDを設定
      this.clientSid = this.locInfo.clientSid;
      // this.getCdName(); // 品番品名取得
      //this.getWarehouse(); // 倉庫リスト取得
      //this.search(true); // 検索実行(初回実行)
      if (
        this.locInfo.name == appConfig.SCREEN_ID.P_MOV_002 ||
        this.locInfo.name == appConfig.SCREEN_ID.P_MOV_004
      ) {
        this.getWarehouseBiz();
      } else {
        this.getWarehouse();
      }
    },

    // 品番/品名（業務利用マスタ)
    // getCdName() {
    //   this.loadingCounter = 1;

    //   const config = this.$httpClient.createGetApiRequestConfig();

    //   config.params.clientSid = this.locInfo.clientSid;
    //   config.params.officeSid = sessionStorage.getItem("sales_office_sid");

    //   return new Promise((resolve, reject) => {
    //     this.$httpClient
    //       // 接続先のAPI_URLを入力
    //       .secureGet(appConfig.API_URL.MST_ITEMBIZ2, config)
    //       .then((response) => {
    //         // console.debug("getCdName() Response", response);
    //         const jsonData = JSON.parse(JSON.stringify(response.data));

    //         // 正常時
    //         if (jsonData.resCom.resComCode == "000") {
    //           const list = [{ text: "", value: "" }];
    //           jsonData.resIdv.ItemsBizUse.forEach((row) => {
    //             list.push({
    //               text: `${row.itemCd}` + `（${row.itemName}）`,
    //               value: row.itemStandardSid,
    //               name: row.itemName,
    //             });
    //           });
    //           this.prodNameList = list;

    //           resolve();
    //         } else {
    //           // エラー時
    //           // エラーメッセージをpopupのmessageに格納
    //           this.infoDialog.message = jsonData.resCom.resComMessage;
    //           this.infoDialog.title = "結果";
    //           this.infoDialog.isOpen = true;
    //           this.infoDialog.screenFlag = true;
    //           this.infoDialog.firstPageFlag = true;
    //         }
    //         reject();
    //       })
    //       .then(() => {
    //         if (
    //           this.locInfo.name == appConfig.SCREEN_ID.P_MOV_002 ||
    //           this.locInfo.name == appConfig.SCREEN_ID.P_MOV_004
    //         ) {
    //           this.getWarehouseBiz();
    //         } else {
    //           this.getWarehouse();
    //         }
    //       })
    //       .catch((resolve) => {
    //         this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
    //         this.infoDialog.title = appConfig.DIALOG.title;
    //         this.infoDialog.isOpen = true;
    //         this.infoDialog.screenFlag = true;
    //         this.infoDialog.firstPageFlag = true;

    //         reject(resolve);
    //       })
    //       .finally(() => {
    //         this.loadingCounter = 0;
    //       });
    //   });
    // },

    // 倉庫リストボックス (倉庫マスタ)
    getWarehouse() {
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.isMst = "1"; // マスタ取得有無 0：対象言語のみ、1：言語問わず全権取得
      config.params.searchCategory = "0";
      config.params.divisionName = "a";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseNameList = list;
              resolve();
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .then(() => {
            this.search(true);
          })
          .catch((resolve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            this.loadingCounter = 0;
          });
      });
    },

    // 倉庫マスタ取得
    getWarehouseBiz() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseLanguage[0].warehouseName}` + `(${row.warehouseCd})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseNameList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 検索ボタン押下時処理
     * @param 初期表示フラグ 初期表示の場合はtrue, そうでない場合はfalse
     */
    search(firstDisplayFlg) {
      this.firstDispFlg = firstDisplayFlg;
      // クリア処理
      this.tempLocationList = [];
      // this.locationList = [];
      // 検索方法の判定
      if (this.searchMethod == SEARCH_METHOD_ITEM) {
        // 品番品名を選択された場合
        // 在庫照会API実行と空き治具数取得
        this.getStockInfoList(firstDisplayFlg);
      } else {
        // ロケ倉庫を選択した場合
        // 格納可能ロケーションの取得と空き治具数取得
        this.locationSearch();
      }
    },
    /**
     * 空き治具数を取得する(非同期処理用メソッド)
     */
    searchJig(firstDisplayFlg) {
      this.firstDispFlg = firstDisplayFlg;
      // ローディング画面表示ON
      this.loadingCounter = 1;
      for (let i = 0; i < this.tempLocationList.length; i++) {
        // 空き治具数を取得する。
        this.getJigEmptyCount(i, firstDisplayFlg);
      }
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 在庫詳細情報を取得する。（在庫詳細照会API）
     * @return 在庫詳細情報
     */
    getStockInfoList(firstDisplayFlg) {
      this.firstDispFlg = firstDisplayFlg;
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 共通IF項目生成
      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SID（親画面項目）
      config.params.clientSid = this.locInfo.clientSid;

      // 検索方法が品番/品名の場合
      // 商品標準SID（親画面項目）
      if (this.ProdNameSelected) {
        config.params.itemStandardSid = this.ProdNameSelected;
      } else {
        if (firstDisplayFlg) {
          config.params.itemStandardSid = this.locInfo.itemStandardSid;
        } else {
          // 処理なし
        }
      }
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      config.params.reqComPageIndex = this.page;

      // はい替え日（親画面項目）
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.locInfo.referenceDate);
      // console.debug("getStockInfoList(firstDisplayFlg) Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_STOCKDTL, config)
          .then((response) => {
            // console.debug("getStockInfoList(firstDisplayFlg) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              jsonData.resIdv.stocksDetail.forEach((row) => {
                list.push({
                  // 商品標準SID
                  itemStandardSid: row.itemStandardSid,
                  // 品番
                  productCd: row.itemCd.substring(0, 50).trim(),
                  incidental: row.itemCd.slice(-50).trim(),
                  // 品名
                  product: row.itemName,
                  // 品質区分
                  qualityDiv: row.qualityDiv,
                  // ロケーションSID
                  locationSid: row.locationSid,
                  // 倉庫名
                  warehouse: row.warehouseName,
                  // 倉庫SID
                  warehouseSid: row.warehouseSid ? row.warehouseSid : "",
                  // ロケーションNo
                  location: row.locationNo ? row.locationNo : "",
                  // ロット
                  lotNo: row.lotNo,
                  // 在庫数
                  qty: row.stocks,
                });
              });

              this.tempLocationList = list;
              if (list.length == 0) {
                this.locationList = list;
              }
              resolve();
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .then(() => {
            // 空き治具数を取得する。
            this.searchJig(firstDisplayFlg);
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 空き治具数を取得する。
     * @param index
     * @param 初期表示フラグ
     */
    getJigEmptyCount(index, firstDisplayFlg) {
      this.firstDispFlg = firstDisplayFlg;
      // ローディング画面表示ON
      //this.loadingCounter = 1;

      // 明細を取得
      let info = this.tempLocationList[index];

      // 共通IF項目生成
      const config = this.$httpClient.createGetApiRequestConfig();
      // 取引先SID
      config.params.clientSid = this.clientSid;
      // 商品標準SID
      if (this.ProdNameSelected) {
        config.params.itemStandardSid = this.ProdNameSelected;
      } else {
        if (firstDisplayFlg) {
          config.params.itemStandardSid = this.locInfo.itemStandardSid;
        } else {
          // 処理なし
        }
      }
      // ロケーションSID
      if (this.searchMethod == SEARCH_METHOD_ITEM) {
        // 検索方法が品番品名を選択された場合
        config.params.locationSid = info.locationSid;
      } else {
        config.params.locationSid = this.tempLocationList[index].locationSid;
      }

      // 検索区分("0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得)
      config.params.searchCategoryDiv = "0";
      // はい替え日（基準日）
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(info.referenceDate);
      config.params.reqComPaginationFlg = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_AVAILABLESPACE, config)
          .then((response) => {
            // console.debug("getJigEmptyCount(index, firstDisplayFlg) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 空き治具数、空き容量を設定する。
              for (let i = 0; i < jsonData.resIdv.locations.length; i++) {
                // 1件しか取得できないので常に１件目を設定する。
                info.emptyJigCount = String(jsonData.resIdv.locations[0].jigStorableQuantity);
                info.freeSpace = String(jsonData.resIdv.locations[0].availableCapacity);
              }
              resolve();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .then(() => {
            // 一時用のリストを実際のリストに設定
            this.locationList = [];
            this.locationList = this.tempLocationList;
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            //this.loadingCounter = 0;
          });
      });
    },

    // 格納可能ロケーションの取得 (格納可能ロケ一覧API)
    locationSearch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 検索項目：倉庫
      let selectWarehouse = this.warehouseSelected;
      // 検索項目：ロケーションNo
      let locationWord = this.txt_location;

      // IF共通項目生成
      const config = this.$httpClient.createGetApiRequestConfig();
      // マスタ取得有無 0：対象言語のみ、1：言語問わず全権取得
      config.params.isMst = "1";
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ページャー処理
      if (this.page > 2 && this.locationList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // 基準日
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.locInfo.referenceDate);
      // 倉庫選択値
      if (selectWarehouse) {
        config.params.warehouseSid = selectWarehouse;
      } else {
        // 処理なし
      }

      // ロケーション入力値
      if (locationWord) {
        config.params.locationNo = locationWord;
      } else {
        // 処理なし
      }

      // console.debug("locationSearch() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
          .then((response) => {
            // console.debug("locationSearch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  warehouse: row.warehouseName,
                  warehouseSid: row.warehouseSid,
                  warehouseNameShort: row.warehouseNameShort,
                  location: row.locationNo,
                  locationSid: row.locationSid,
                  productCd: row.itemCd,
                  product: row.itemName,
                  qty: row.stock,
                  // 空き治具数は取得できないので一律ハイフン
                  emptyJigCount: "-",
                  freeSpace: row.availableCapacity,
                });
                this.tempLocationList = list;
              });
            } else {
              // エラー時

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }

            resolve(response);
          })
          .then(() => {
            // FIXME 空き治具数は商品名が分からないので取得できない。
            // 空き治具数を取得する。
            //this.searchJig(false);
            // 一時用のリストを実際のリストに設定
            this.locationList = [];
            this.locationList = this.tempLocationList;
            // クリア
            //this.totalCount = 0;
            // 総件数を設定
            //this.totalCount = this.locationList.length;
          })
          .catch((resolve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // // 推奨ロケ検索
    // autoSearch() {
    //   this.locationList = [];
    //   for (var i = 1; i < 4; i++) {
    //     for (var j = 0; j < 5; j++) {
    //       var location = {
    //         warehouse: "warehouse" + i,
    //         location: i + "_" + j,
    //         productCd: this.locInfo.locProductCd,
    //         qty: i * 100,
    //       };
    //       this.locationList.push(location);
    //     }
    //   }
    // },
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      // this.page = 1;
      this.search();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      // this.page = 1;
      this.search();
    },

    // 選択したロケーション行
    selectLocation(data) {
      if (data.locationSid == this.locInfo.locationSid) {
        this.updateDialogMessage = true;
        this.alertMessage = messsageUtil.getMessage("P-MOV-002_001_E");
      } else {
        this.$emit("setLocation", data);
      }
    },
    // アラートダイアログ
    okClicked() {
      this.updateDialogMessage = false;
    },
    /**
     * 閉じるボタン押下時処理locInfoinit
     */
    close() {
      this.ProdNameSelected = "";
      this.warehouseSelected = "";
      this.txt_location = "";
      this.locationList = [];
      this.pageCount = 1;
      this.totalCount = 0;
      this.$emit("close");
    },
    reload() {},
    /**
     * 検索方法選択処理
     */
    changeSearchMethod() {
      if (this.searchMethod == "01") {
        this.searchMethodDispFlag1 = true;
        this.searchMethodDispFlag2 = false;
      } else {
        this.searchMethodDispFlag1 = false;
        this.searchMethodDispFlag2 = true;
      }
      this.page = 1;
    },
  },
  computed: {},
  watch: {
    locInfo: function () {
      this.init();
    },
    locationList: {
      handler: function () {
        // console.debug("watch locationList start");
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(this.firstDispFlg);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
// popup画面の為、本画面ではmargin-leftは別設定
.search-button {
  width: 7rem;
}
.search-result {
  margin: 0 0 0 71%;
}

//データテーブル

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.first-btn-search {
  margin-left: -1.7%;
}

.search-autocomplete-full {
  width: 17rem;
}

#get-item-cd ::v-deep i {
  display: none;
}
</style>
